const { gql } = require('@apollo/client');

export const ORGANIZATION_GET_BY_ID = gql`
  query getOrganizationById($id: ObjectId!) {
    organization(query: { _id: $id }) {
      _id
      address1
      address2
      city
      country
      createdBy
      creationDate
      email
      logo_url
      name
      state
      taxId
      website
      zip
      currency
      # stripeAccountId
      team {
        _id
        userId
        email
        name
        roles
      }
      stripeTaxRates {
        stripeTaxRateId
        name
        countryCode
        taxRateInteger
        description
        inclusive
        active
      }
      merchandiseShippingFee
      ticketShippingFee
      stripeAccountId
    }
  }
`;
