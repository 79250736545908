/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from 'react';

// @mui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonSelect from 'components/ArgonSelect';
import ArgonTagInput from 'components/ArgonTagInput';

// Settings page components
import FormField from 'layouts/pages/account/components/FormField';

// Data
import selectData from 'options/selectData';
import ArgonButton from 'components/ArgonButton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ArgonDatePicker from 'components/ArgonDatePicker';
// import ObjectID from 'bson-objectid';
import {
  Collapse,
  Icon,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableHead,
  TableRow
} from '@mui/material';

import TableCell from 'layouts/pages/account/settings/components/TableCell';
import ArgonInput from 'components/ArgonInput';
import DivisionList from '../Divisions/DivisionList';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';

import { EVENTINSTANCE_UPDATE } from 'GraphQL/EventInstances/mutations';
import { EVENTINSTANCE_DELETE } from 'GraphQL/EventInstances/mutations';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import Swal from 'sweetalert2';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { FormatMoney } from 'format-money-js';
import { useSnackbar } from 'notistack';
import { EVENTINSTANCE_GET_BY_ID } from 'GraphQL/EventInstances/queries';
import Loader from 'Loader';
import { EVENT_GET_BY_ID } from 'GraphQL/Events/queries';
import ArgonCurrencyInput from 'components/ArgonCurrencyInput';
import { useNavigate } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import ArgonEditor from 'components/ArgonEditor';
import ObjectID from 'bson-objectid';
import { deactivateStripeProduct } from 'services/REST_API/stripe';
import { updateStripeProduct } from 'services/REST_API/stripe';
import { createStripeProduct } from 'services/REST_API/stripe';
import { setNewPriceForStripeProduct } from 'services/REST_API/stripe';
import StripeTaxRateSelector from 'Routes/Organizer/Organization/Settings/Payments/StripeTaxRates/Selector';
import { Link } from 'react-router-dom';
import FormikNumberInput from 'components/FormikNumberInput';
import FormikCountrySelector from 'components/FormikCountrySelector';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { nanoid } from 'nanoid';
import ArgonDropzoneS3 from 'components/ArgonDropzoneS3';
import { timeZones } from 'options/timeZones';

import AutoInvitationSettingsWizard from '../Auto-Invitation/Wizard/AutoInvitationSettingsWizard';
import AutoInvitationStatistics from '../Auto-Invitation/Statistics';

function EventInstanceDetails({
  eventInstanceId,
  // eventInstance,
  // event,
  open,
  addNew,
  addNewEventType,
  onEventInstanceChanged
}) {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  moment.locale(i18n.language);

  const [workInProgress, setWorkInProgress] = useState(false);

  const [expanded, setExpanded] = useState(open || true);
  const [divisionsExpanded, setDivisionsExpanded] = useState(true);

  const [autoInvitationsExpanded, setAutoInvitationsExpanded] = useState(false);
  const [autoInvitationWizardOpen, setAutoInvitationWizardOpen] =
    useState(false);
  const [showInvitationProgress, setShowInvitationProgress] = useState(false);

  const [event, setEvent] = useState({});

  const [gqlUpdateEventInstance] = useMutation(EVENTINSTANCE_UPDATE);
  const [gqlDeleteEventInstance] = useMutation(EVENTINSTANCE_DELETE);

  const [editor1Key, setEditor1Key] = useState(nanoid());
  const [editor2Key, setEditor2Key] = useState(nanoid());

  const [scheduleAttachments, setScheduleAttachments] = useState([]);

  const { data, loading, refetch } = useQuery(EVENTINSTANCE_GET_BY_ID, {
    variables: { eventInstanceId: eventInstanceId },
    onCompleted: async (data) => {
      console.log('onCompleted', data);

      await getEvent({ variables: { eventId: data.eventInstance?.eventId } });

      // provide formik with values from database

      setScheduleAttachments(data?.eventInstance?.scheduleAttachments || []);

      formik.setFieldValue('_id', data.eventInstance?._id);
      formik.setFieldValue('eventId', data.eventInstance?.event?.id);
      formik.setFieldValue('creationDate', data.eventInstance?.creationDate);

      // creationDate: data.eventInstance?.creationDate ||
      //   new Date().toDateString(),

      formik.setFieldValue(
        'type',
        data.eventInstance?.type || addNewEventType || ''
      );

      formik.setFieldValue('startDate', data.eventInstance?.startDate || '');
      formik.setFieldValue('endDate', data.eventInstance?.endDate || '');
      formik.setFieldValue(
        'isVirtualEvent',
        data.eventInstance?.isVirtualEvent || false
      );
      formik.setFieldValue(
        'isOpenRegistration',
        data.eventInstance?.isOpenRegistration
      );

      // isOpenRegistration: eventInstance?.isOpenRegistration ||
      //   // Boolean(addNewEventType === "onlineQualifier") ||
      //   true,

      formik.setFieldValue('name', data.eventInstance?.name || '');
      formik.setFieldValue(
        'description',
        data.eventInstance?.description || ''
      );
      formik.setFieldValue(
        'registrationDeadline',
        data.eventInstance?.registrationDeadline || ''
      );
      formik.setFieldValue(
        'organizationId',
        data.eventInstance?.organizationId || ''
      );
      formik.setFieldValue(
        'maxParticipants',
        data.eventInstance?.maxParticipants || 0
      );
      formik.setFieldValue(
        'registrationFee',
        Number(data.eventInstance?.registrationFee) / 100 || 0
      );
      formik.setFieldValue(
        'address1',
        data?.eventInstance?.location?.address1 || ''
      );
      formik.setFieldValue(
        'address2',
        data?.eventInstance?.location?.address2 || ''
      );
      formik.setFieldValue('zip', data?.eventInstance?.location?.zip || '');
      formik.setFieldValue('city', data?.eventInstance?.location?.city || '');
      formik.setFieldValue(
        'country',
        data?.eventInstance?.location?.country || ''
      );
      formik.setFieldValue('timeZone', data?.eventInstance?.timeZone || '');
      formik.setFieldValue(
        'stripeTaxRateId',
        data?.eventInstance?.stripeTaxRateId || ''
      );
      formik.setFieldValue(
        'stripeProductId',
        data?.eventInstance?.stripeProductId || ''
      );
      formik.setFieldValue(
        'stripePriceId',
        data?.eventInstance?.stripePriceId || ''
      );

      formik.setFieldValue(
        'setLimitPerEventInstance',
        Boolean(data?.eventInstance?.maxParticipants)
      );
      formik.setFieldValue(
        'maxParticipants',
        data?.eventInstance?.maxParticipants || null
      );

      formik.setFieldValue(
        'askForTShirtSize',

        data?.eventInstance?.customRequiredFields?.find(
          (crf) => crf.name === 'tShirtSize'
        )?.required == true || false
      );

      formik.setFieldValue(
        'askForCountry',
        data?.eventInstance?.customRequiredFields?.find(
          (crf) => crf.name === 'country'
        )?.required == true || false
      );

      formik.setFieldValue(
        'scheduleDetails',
        data?.eventInstance?.scheduleDetails || null
      );
    },
    onError: (error) => {
      console.error('onError', error);
    },

    nextFetchPolicy: 'network-only'
  });

  const [getEvent] = useLazyQuery(EVENT_GET_BY_ID, {
    onCompleted: (data) => {
      console.log('onCompleted: event data fetched:', data);
      setEvent(data.event);
    }
  });

  const eventInstance = data?.eventInstance;

  const getDecimalSeparator = (locale) => {
    const numberWithDecimalSeparator = 1.1;
    return Intl.NumberFormat(locale || i18n.language)
      .formatToParts(numberWithDecimalSeparator)
      .find((part) => part.type === 'decimal').value;
  };

  const fm = new FormatMoney({
    decimals: 2,
    locale: i18n.language,
    decimalPoint: getDecimalSeparator(i18n.language)
  });

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  }));

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const validationSchema = Yup.object({
    type: Yup.string()
      .oneOf(selectData.eventInstanceTypes.map((item) => item.value))
      .required('Required'),

    registrationDeadline: Yup.string().required('Required'),
    // registrationFee: Yup.number('Must be a valid number'),
    startDate: Yup.date('Must be a valid date').required('Required'),
    endDate: Yup.date('Must be a valid date').required('Required')
  });

  const formik = useFormik({
    initialValues: {
      _id: eventInstance?._id, //|| ObjectID.toHexString(),
      eventId: eventInstance?.eventId || '',
      creationDate: eventInstance?.creationDate || new Date().toDateString(),

      type: eventInstance?.type || addNewEventType || '',
      startDate: eventInstance?.startDate || '',
      endDate: eventInstance?.endDate || '',

      isVirtualEvent:
        eventInstance?.isVirtualEvent ||
        // Boolean(addNewEventType === "onlineQualifier") ||
        false,

      isOpenRegistration:
        eventInstance?.isOpenRegistration ||
        // Boolean(addNewEventType === "onlineQualifier") ||
        true,
      name: eventInstance?.name || '',
      description: eventInstance?.description || '',
      registrationDeadline: eventInstance?.registrationDeadline || '',
      organizationId:
        eventInstance?.organizationId || eventInstance?.event?._id || '',
      maxParticipants: eventInstance?.maxParticipants || 0,
      setLimitPerEventInstance:
        Boolean(eventInstance?.maxParticipants) || false,
      registrationFee: Number(eventInstance?.registrationFee) / 100 || 0,
      address1: eventInstance?.location?.address1 || '',
      address2: eventInstance?.location?.address2 || '',
      city: eventInstance?.location?.city || '',
      zip: eventInstance?.location?.zip || '',
      country: eventInstance?.location?.country || '',
      timeZone: eventInstance?.timeZone || '',
      stripeProductId: eventInstance?.stripeProductId || '',
      stripePriceId: eventInstance?.stripePriceId || '',
      stripeTaxRateId: eventInstance?.stripeTaxRateId || '',
      scheduleDetails: eventInstance?.scheduleDetails || ''

      // askForTShirtSize: eventInstance?.customRequiredFields?.find(
      //   (crf) => crf.name === 'tShirtSize'
      // )?.required == true,
      // askForCountry: eventInstance?.customRequiredFields?.find(
      //   (crf) => crf.name === 'country'
      // )?.required == true
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setWorkInProgress(true);

      // alert(JSON.stringify(values, null, 2));

      const customRequiredFields = [];

      if (values.askForCountry) {
        customRequiredFields.push({
          name: 'country',
          label: 'Country to represent',
          type: 'countrySelector',
          belongsTo: 'registration',
          required: true
        });
      }

      if (values.askForTShirtSize) {
        customRequiredFields.push({
          name: 'tShirtSize',
          label: 'T-Shirt Size',
          type: 'select',
          belongsTo: 'participant',
          options: [
            {
              value: 'XS',
              label: 'XS'
            },
            {
              value: 'S',
              label: 'S'
            },
            {
              value: 'M',
              label: 'M'
            },
            {
              value: 'L',
              label: 'L'
            },
            {
              value: 'XL',
              label: 'XL'
            },
            {
              value: 'XXL',
              label: 'XXL'
            }
          ],

          required: true
        });
      }

      if (!addNew) {
        let stripeProduct;

        console.log('eventInstance to update:', eventInstance);

        // if there is a stripe product id, update the product; if not, create a new one
        if (eventInstance?.stripeProductId) {
          console.log('updating stripe product');
          await updateStripeProduct({
            organizationId: values?.organizationId,
            productId: eventInstance?.stripeProductId,
            productName:
              eventInstance?.event?.name +
              ' - ' +
              (values?.name ||
                selectData.eventInstanceTypes.find(
                  (eit) => eit.value === values?.type
                ).label),
            description: values.description
          }).catch((err) => {
            console.error(
              'error updating stripe product for updated eventInstance',
              err
            );
          });

          // if price has changed, set new price
          if (
            fm.un(values?.registrationFee) * 100 !==
            eventInstance?.registrationFee
          ) {
            console.log('Updating stripe price');
            await setNewPriceForStripeProduct({
              organizationId: values?.organizationId,
              productId: eventInstance?.stripeProductId,

              newPriceInCents: fm.un(values?.registrationFee) * 100,
              newCurrency:
                values?.currency ||
                eventInstance?.currency ||
                eventInstance?.event?.currency ||
                eventInstance?.event?.organization?.currency ||
                'EUR'
            }).catch((err) => {
              console.error(
                'error updating stripe price for updated eventInstance',
                err
              );
            });
          } else {
            console.log('Price has not changed. Not updated in stripe.');
          }
        } else {
          // if orga has stripe account connected, create a product
          if (eventInstance?.event?.organization?.stripeAccountId) {
            console.log('creating new stripe product');
            stripeProduct = await createStripeProduct({
              organizationId: values?.organizationId,
              productName:
                eventInstance?.event?.name +
                ' - ' +
                selectData.eventInstanceTypes.find(
                  (eit) => eit.value === values?.type
                ).label,

              defaultCurrency:
                values?.currency ||
                eventInstance?.event?.currency ||
                eventInstance?.event?.organization?.currency ||
                'EUR',
              defaultPrice: 0,
              stripeProductTaxCode: 'txcd_20030000',
              imageUrl: eventInstance?.event?.pic_logo_url,
              productType: 'eventInstance',
              productDbId: eventInstanceId
            }).catch((err) => {
              console.error('Error creating stripe product', err);
            });
            console.log('New Stripe Product: ', stripeProduct);
          }
        }

        gqlUpdateEventInstance({
          variables: {
            eventInstanceId: values._id,
            data: {
              type: values.type,
              startDate: values.startDate,
              endDate: values.endDate,
              isVirtualEvent: values.isVirtualEvent,
              isOpenRegistration: values.isOpenRegistration,
              name: values.name,
              description: values.description,
              registrationDeadline: values.registrationDeadline,
              organizationId: values.organizationId,
              maxParticipants: values.maxParticipants,
              registrationFee: fm.un(values.registrationFee) * 100,
              location: {
                address1: values.address1,
                address2: values.address2,
                city: values.city,
                zip: values.zip,
                country: values.country
              },
              timeZone: values.timeZone,
              stripeProductId: stripeProduct?.id,
              stripePriceId: stripeProduct?.default_price,
              stripeTaxRateId: values.stripeTaxRateId,
              customRequiredFields: customRequiredFields,
              scheduleDetails: values.scheduleDetails
            }
          }
        })
          .then(async (data) => {
            console.log('result from update operation', data);

            enqueueSnackbar('Your changes have been saved.', {
              variant: 'success'
            });
            // Swal.fire('Success!', 'Your profile has been updated!', 'success');
          })
          .catch((err) => {
            console.error('error', err);
          })
          .finally(() => {
            setWorkInProgress(false);
          });
      }
    }
  });

  const handleDeleteEventInstanceButtonClicked = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        // first, remove stripe product
        if (eventInstance?.stripeProductId) {
          console.log('Removing stripe product for eventInstance');
          await deactivateStripeProduct({
            organizationId: eventInstance?.organizationId,
            productId: eventInstance?.stripeProductId
          }).catch((err) => {
            console.error(
              'error deactivating stripe product for removed eventInstance',
              err
            );
          });
        }

        console.log('Deleting eventInstance', eventInstance._id);

        // then, delete eventInstance from database
        gqlDeleteEventInstance({
          variables: {
            eventInstanceId: eventInstance._id
          }
        })
          .then((data) => {
            console.log('result from delete operation', data);
            onEventInstanceChanged && onEventInstanceChanged();
            enqueueSnackbar('Your event instance has been deleted.', {
              variant: 'success'
            });
            // Swal.fire(
            //   'Deleted!',
            //   'Your event instance has been deleted.',
            //   'success'
            // );

            navigate('/organizer/events/' + eventInstance?.event?._id, {
              replace: true
            });
          })
          .catch((err) => {
            console.error('error', err);
          });
      }
    });
  };

  // const handleSwitchChanged = async (event) => {
  //   formik.setFieldValue(event.target.name, event.target.checked);
  // };

  const handleDatePickerChanged = (fieldName, newValue) => {
    console.log(fieldName, newValue[0]);
    if (newValue.length > 0) {
      formik.setFieldValue(fieldName, newValue[0]);
    } else {
      console.error(
        'New picked date is an array with multiple date. Cannot update.'
      );
    }
  };

  const handleSelectMenuChanged = (fieldName, option) => {
    formik.setFieldValue(fieldName, option);
  };

  var decimalFormatter = new Intl.NumberFormat(i18n.language, {
    // style: "currency",
    // currency: event?.currency || "EUR",
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  });

  // console.log('Event Instance Details: Data', data);

  if (!data) return <Loader />;

  const calculatedSystemFee = decimalFormatter.format(
    // if no fee is asked, do not ask for a platform fee
    formik.values?.registrationFee == '0'
      ? 0
      : Number(process.env.REACT_APP_TDU_FIXED_FEE) +
          Number(
            (process.env.REACT_APP_TDU_PERCENTAGE_FEE / 100) *
              fm.un(formik.values?.registrationFee)
          )
  );

  const paymentsEnabled = eventInstance.event.organization?.stripeAccountId;

  console.log('EventInstanceDetails: paymentsEnabled', paymentsEnabled);

  const handleFileUploaded = (url, fileName) => {
    console.log('handleFileUploaded', url);
    setScheduleAttachments((attachments) => [
      ...attachments,
      { url, fileName }
    ]);
  };

  const handleDivisionsChanged = async () => {
    console.log('EventInstance Setup: handleDivisionsChanged');
    await refetch();
  };

  const formMinWidth = '200px';

  return (
    <>
      <Card id={eventInstance?._id} sx={{ overflow: 'visible' }}>
        <ArgonBox
          p={3}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          onClick={handleExpandClick}
          style={{ cursor: 'pointer' }}>
          <ArgonTypography variant="h5" textTransform="capitalize">
            {!formik.values.name &&
              !formik.values?.type &&
              'Event Instance Details'}
            {formik.values.name && formik.values.name}
            {!formik.values.name &&
              formik.values?.type &&
              `${formik.values.name} (${
                selectData.eventInstanceTypes.find(
                  (eit) => eit.value === formik.values.type
                )?.label || ''
              })`}
          </ArgonTypography>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more">
            <ExpandMoreIcon />
          </ExpandMore>
        </ArgonBox>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <ArgonBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%">
                  <ArgonBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block">
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      // textTransform="capitalize"
                    >
                      Event Instance Type
                    </ArgonTypography>
                  </ArgonBox>
                  <ArgonSelect
                    id="sports"
                    name="sports"
                    placeholder="Please choose"
                    options={selectData.eventInstanceTypes}
                    //value={formik.values.type}
                    value={selectData.eventInstanceTypes.filter(
                      (option) => option.value === formik.values.type
                    )}
                    onChange={(option) =>
                      handleSelectMenuChanged('type', option.value)
                    }
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                  />
                </ArgonBox>
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormField
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  label="Name (optional)"
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  placeholder="You can add a name / identifier for your event instance here"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                {/* <FormField
                  id="description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                  label="Description (optional)"
                  multiline
                  rows={4}
                  placeholder="You can add a desciption for your event instance here"
                /> */}
                <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize">
                    Description (optional)
                  </ArgonTypography>
                </ArgonBox>
                <ArgonEditor
                  id="description"
                  name="description"
                  // uniqueKey={division?._id + movements?.hashCode()}
                  uniqueKey={editor1Key}
                  value={formik.values.description}
                  onChange={(value) =>
                    formik.setFieldValue('description', value)
                  }
                  placeholder="You can add a desciption for your event instance here"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Table>
                  {/* Empty table head required to get rid of warning in dev tools */}

                  <TableHead></TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        Event Instance Parameters
                      </TableCell>
                      <TableCell
                        width="100%"
                        padding={[1, 1, 1, 0.5]}
                        sx={{ minWidth: formMinWidth }}>
                        {' '}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            variant="caption"
                            fontWeight="bold">
                            Is a virtual event
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            Competitors will have to submit scores and videos
                            online
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>
                      <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                        <Switch
                          id="isVirtualEvent"
                          name="isVirtualEvent"
                          onChange={formik.handleChange}
                          checked={formik.values.isVirtualEvent}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            variant="caption"
                            fontWeight="bold">
                            Open registration
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            <ArgonBox color="inherit" lineHeight="1.4" mt={1}>
                              Athletes can register themselves. If this is not
                              checked, you will have to manually invite
                              competitors (e.g. to a finale).
                            </ArgonBox>
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>
                      <TableCell
                        align="end"
                        padding={[1, 1, 1, 0.5]}
                        sx={{ minWidth: '220px' }}>
                        <Switch
                          id="isOpenRegistration"
                          name="isOpenRegistration"
                          onChange={(event) => {
                            console.log(
                              'isOpenRegistration',
                              event.target.name,
                              event.target.checked
                            );
                            formik.setFieldValue(
                              'isOpenRegistration',
                              event.target.checked
                            );
                          }}
                          checked={
                            Boolean(formik.values?.isOpenRegistration) || false
                          }
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            variant="caption"
                            fontWeight="bold">
                            Limit of participants / teams across all divisions
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            <ArgonBox color="inherit" lineHeight="1.4" mt={1}>
                              Usually, participants / team limits will be set
                              per division. Select yes if you choose to limit
                              the number of participants (or teams) per event
                              instance.
                            </ArgonBox>
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>
                      <TableCell
                        align="end"
                        padding={[1, 1, 1, 0.5]}
                        sx={{ minWidth: '220px' }}>
                        <ArgonBox
                          display="flex"
                          flexDirection="column"
                          alignItems="end"
                          gap={1}>
                          <Switch
                            id="setLimitPerEventInstance"
                            name="setLimitPerEventInstance"
                            onChange={formik.handleChange}
                            checked={formik.values?.setLimitPerEventInstance}
                          />
                          <Collapse
                            in={formik.values?.setLimitPerEventInstance}>
                            <FormikNumberInput
                              style={{ minWidth: formMinWidth }}
                              id="maxParticipants"
                              name="maxParticipants"
                              label=""
                              placeholder=""
                              formik={formik}
                              // <ArgonInput
                              //   id="maxParticipants"
                              //   name="maxParticipants"
                              //   label=""
                              //   placeholder=""
                              //   value={formik.values?.maxParticipants}
                              //   onChange={formik.handleChange}
                              //   error={
                              //     formik.touched.maxParticipants &&
                              //     Boolean(formik.errors.maxParticipants)
                              //   }
                              //   helperText={
                              //     formik.touched.maxParticipants &&
                              //     formik.errors.maxParticipants
                              //   }
                              size="small"
                              // startAdornment={
                              //   <ArgonBox mr={1} ml={0}>
                              //     #
                              //   </ArgonBox>
                              // }
                              prefix="# "
                            />
                          </Collapse>
                        </ArgonBox>
                      </TableCell>
                    </TableRow>

                    {/* <TableRow>
                    <TableCell
                      padding={[1, 1, 1, 0.5]}
                      sx={{ minWidth: formMinWidth }}>
                      <ArgonBox lineHeight={1.4}>
                        <ArgonTypography
                          display="block"
                          // variant="button"
                          // fontWeight="regular"
                          variant="caption"
                          fontWeight="bold">
                          Max # participants
                        </ArgonTypography>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="regular">
                          Set a limit of how many participants can register
                          across all divisions. Leave empty if there is no
                          limit.
                        </ArgonTypography>
                      </ArgonBox>
                    </TableCell>

                    <TableCell
                      align="end"
                      padding={[1, 1, 1, 0.5]}
                      sx={{ minWidth: formMinWidth }}>
                      <ArgonInput
                        sx={{ minWidth: formMinWidth }}
                        id="participantLimit"
                        name="participantLimit"
                        label=""
                        placeholder=""
                        value={formik.values?.participantLimit}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.participantLimit &&
                          Boolean(formik.errors.participantLimit)
                        }
                        helperText={
                          formik.touched.participantLimit &&
                          formik.errors.participantLimit
                        }
                        size="small"
                        startAdornment={
                          <ArgonBox mr={1} ml={0}>
                            #
                          </ArgonBox>
                        }
                      />
                    </TableCell>
                  </TableRow> */}

                    <TableRow>
                      <TableCell padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            // variant="button"
                            // fontWeight="regular"
                            variant="caption"
                            fontWeight="bold">
                            Registration fee
                          </ArgonTypography>
                          <Collapse in={!paymentsEnabled}>
                            <ArgonBox mt={1} mb={1}>
                              <ArgonTypography
                                variant="caption"
                                color="warning"
                                fontWeight="bold"
                                display="flex"
                                alignItems="center">
                                <ErrorOutlineIcon fontSize="xs" />
                                &nbsp;You have not connected a stripe account to
                                accept payments. Go to&nbsp;
                                <u>
                                  <Link
                                    to="/organizer/organization/settings/payments"
                                    style={{
                                      textDecoration: 'undeline',
                                      color: 'inherit'
                                    }}>
                                    Setup / Payments & Shop
                                  </Link>
                                </u>
                                &nbsp;to fix.
                              </ArgonTypography>
                            </ArgonBox>
                          </Collapse>

                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            <ArgonBox color="inherit" lineHeight="1.4" mt={1}>
                              You can set a fee for registering for this event.
                              You an also define an individual fee per disivion.
                              <br />
                              Your payout will be reduced by the payment
                              provider's transaction fees for the used payment
                              method (see{' '}
                              <a
                                href="https://stripe.com/en-de/pricing"
                                style={{
                                  textDecoration: 'none',
                                  color: 'inherit'
                                }}>
                                Stripe pricing
                              </a>
                              ).
                            </ArgonBox>
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>

                      <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                        <ArgonCurrencyInput
                          id="registrationFee"
                          name="registrationFee"
                          size="small"
                          // decimalSeparator=","
                          // groupSeparator="."
                          intlConfig={{
                            locale: i18n.language,
                            currency: event.currency || 'EUR'
                          }}
                          // prefix={currency || 'EUR' + ' '}
                          allowNegativeValue={false}
                          placeholder=""
                          defaultValue={0}
                          decimalsLimit={2}
                          // fixedDecimalLength={2}
                          value={formik?.values?.registrationFee}
                          onValueChange={(value, name) => {
                            formik.setFieldValue(name, value, true);
                            // formik.setTouched(name, true);
                          }}
                          error={
                            formik.touched.registrationFee &&
                            Boolean(formik.errors.registrationFee)
                          }
                          sx={{ minWidth: formMinWidth }}
                          disabled={!paymentsEnabled}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      {/* <Collapse in={formik.values?.registrationFee > 0}> */}
                      <TableCell padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            // variant="button"
                            // fontWeight="regular"
                            variant="caption"
                            fontWeight="bold">
                            Applicable VAT rate
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            <Collapse
                              in={
                                !paymentsEnabled &&
                                formik.values.registrationFee > 0
                              }>
                              <ArgonBox mt={1} mb={1}>
                                <ArgonTypography
                                  variant="caption"
                                  color="warning"
                                  fontWeight="bold"
                                  display="flex"
                                  alignItems="center">
                                  <ErrorOutlineIcon fontSize="xs" />
                                  &nbsp;You have not connected a stripe account
                                  to accept payments. Go to&nbsp;
                                  <u>
                                    <Link
                                      to="/organizer/organization/settings/payments"
                                      style={{
                                        textDecoration: 'undeline',
                                        color: 'inherit'
                                      }}>
                                      Setup / Payments & Shop
                                    </Link>
                                  </u>
                                  &nbsp;to fix.
                                </ArgonTypography>
                              </ArgonBox>
                            </Collapse>

                            <ArgonBox color="inherit" lineHeight="1.4" mt={1}>
                              Select / add the vat tax rate that applies to the
                              registration fees according to our local tax
                              requirements. For example, this might be the
                              applicable vat rate in the country where the event
                              takes place.{' '}
                            </ArgonBox>
                            <ArgonBox color="inherit" mt={1}></ArgonBox>
                            <ArgonBox color="inherit" mt={1}>
                              Be aware that taxtion is a complex issue.{' '}
                              <u>
                                We strongly recommend you consult your tax
                                advisor.
                              </u>{' '}
                              STRONG.ONE cannot take any liability for the
                              correct presentation, collection and reporting of
                              your taxes.
                              {/* The payment provider Stripe offers a
                              feature to automate tax collection and reporting.
                              Activate this feature in your{' '}
                              <a
                                href="https://www.stripe.com"
                                target="_blank"
                                style={{
                                  color: 'inherit'
                                }}>
                                stripe account
                              </a>{' '}
                              and mark it active in your{' '}
                              <Link
                                to="/organizer/dashboard"
                                style={{
                                  color: 'inherit'
                                }}>
                                organization settings
                              </Link>
                              . */}
                            </ArgonBox>
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>

                      <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                        <StripeTaxRateSelector
                          disabled={
                            !paymentsEnabled ||
                            !formik.values.registrationFee > 0
                          }
                          organization={eventInstance?.event?.organization}
                          selectedTaxRateId={formik.values.stripeTaxRateId}
                          onChange={(taxRateOption) => {
                            console.log(
                              'EventInstance: New taxrate option received',
                              taxRateOption
                            );
                            formik.setFieldValue(
                              'stripeTaxRateId',
                              taxRateOption?.value
                            );
                          }}
                          size="small"
                        />
                      </TableCell>
                      {/* </Collapse> */}
                    </TableRow>

                    <TableRow>
                      <TableCell padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            // variant="button"
                            // fontWeight="regular"
                            variant="caption"
                            fontWeight="bold">
                            System fee
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            This fee will be added to the registration fee and
                            automatically forwarded to{' '}
                            {process.env.REACT_APP_PLATTFORM_NAME}; see our{' '}
                            <a
                              href="/pricing"
                              style={{
                                textDecoration: 'none',
                                color: 'inherit'
                              }}>
                              pricing table
                            </a>
                            .
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>

                      <TableCell align="start" padding={[1, 1, 1, 0.5]}>
                        <ArgonBox width="100%">
                          {/* <ArgonInput
                          size="small"
                          disabled
                          startAdornment={
                            <ArgonBox mr={1} ml={0}>
                              {event?.currency || 'EUR'}
                            </ArgonBox>
                          }
                          value={
                            formik.values?.registrationFee > 0
                              ? `${decimalFormatter.format(
                                  Number(process.env.REACT_APP_TDU_FIXED_FEE) +
                                    Number(
                                      (process.env
                                        .REACT_APP_TDU_PERCENTAGE_FEE /
                                        100) *
                                        formik.values?.registrationFee
                                    )
                                )}`
                              : `${event?.currency} ${decimalFormatter.format(
                                  Number(process.env.REACT_APP_TDU_FIXED_FEE)
                                )} `
                          }
                        /> */}
                          <ArgonCurrencyInput
                            id="systemFee"
                            name="systemFee"
                            size="small"
                            intlConfig={{
                              locale: i18n.language,
                              currency: event.currency || 'EUR'
                            }}
                            disabled
                            // prefix={currency || 'EUR' + ' '}
                            allowNegativeValue={false}
                            placeholder=""
                            defaultValue={0}
                            decimalsLimit={2}
                            value={calculatedSystemFee}
                            // onChange={formik.handleChange}
                            // onValueChange={(value, name) => {
                            //   console.log(value, name);
                            //   formik.setFieldValue(name, value, true);
                            //   // formik.setTouched(name, true);
                            // }}
                            error={
                              formik.touched.registrationFee &&
                              Boolean(formik.errors.registrationFee)
                            }
                            // transformRawValue={(value) => (value * 100).toString()}
                          />
                        </ArgonBox>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            // variant="button"
                            // fontWeight="regular"
                            variant="caption"
                            fontWeight="bold">
                            Registration deadline
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            Date until which participants can register
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>
                      <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                        <ArgonDatePicker
                          id="registrationDeadline"
                          name="registrationDeadline"
                          options={{
                            allowInput: true,
                            enableTime: true,
                            time_24hr: true,
                            defaultHour: 22
                          }}
                          input={{
                            size: 'small',
                            placeholder: 'Select a date',
                            error:
                              formik.touched.registrationDeadline &&
                              Boolean(formik.errors.registrationDeadline),

                            helperText:
                              formik.touched.registrationDeadline &&
                              formik.errors.registrationDeadline
                          }}
                          onChange={(date) => {
                            handleDatePickerChanged(
                              'registrationDeadline',
                              date
                            );
                          }}
                          value={formik.values.registrationDeadline}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            // variant="button"
                            // fontWeight="regular"
                            variant="caption"
                            fontWeight="bold">
                            Event start date{' '}
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular"></ArgonTypography>
                        </ArgonBox>
                      </TableCell>
                      <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                        <ArgonDatePicker
                          id="startDate"
                          name="startDate"
                          // defaultValue={formik.values.startDate}
                          options={{
                            allowInput: true,
                            enableTime: true,
                            time_24hr: true,
                            defaultHour: 8
                          }}
                          input={{
                            size: 'small',
                            placeholder: 'Select a date',
                            error:
                              formik.touched.startDate &&
                              Boolean(formik.errors.startDate),

                            helperText:
                              formik.touched.startDate &&
                              formik.errors.startDate
                          }}
                          onChange={(date) => {
                            handleDatePickerChanged('startDate', date);
                            console.log('StartDate onChange: ', date);
                            // change hours of date to 22:00
                            let newDate = new Date(date);
                            newDate.setHours(18);
                            console.log('StartDate New Date: ', newDate);

                            if (
                              formik.values.endDate === '' ||
                              formik.values.endDate === null ||
                              formik.values.endDate === undefined
                            ) {
                              console.log(
                                'Setting endDate to updated startDate',
                                newDate
                              );
                              handleDatePickerChanged('endDate', [newDate]);
                            }
                          }}
                          value={formik.values.startDate}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            // variant="button"
                            // fontWeight="regular"
                            variant="caption"
                            fontWeight="bold">
                            Event end date
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular"></ArgonTypography>
                        </ArgonBox>
                      </TableCell>
                      <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                        <ArgonDatePicker
                          id="endDate"
                          name="endDate"
                          options={{
                            allowInput: true,
                            enableTime: true,
                            time_24hr: true,
                            defaultHour: 18
                          }}
                          input={{
                            size: 'small',
                            placeholder: 'Select a date',
                            error:
                              formik.touched.endDate &&
                              Boolean(formik.errors.endDate),

                            helperText:
                              formik.touched.endDate && formik.errors.endDate
                          }}
                          // onChange={handleDatePickerChanged}
                          // onChange={(date) => formik.setFieldValue("date", date)}
                          onChange={(date) => {
                            handleDatePickerChanged('endDate', date);
                          }}
                          value={formik.values.endDate}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Collapse in={!formik?.values?.isVirtualEvent} timeout="auto">
                  <Table>
                    {/* Empty table head required to get rid of warning in dev tools */}

                    <TableHead></TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                          Event Location
                        </TableCell>
                        <TableCell
                          width="100%"
                          padding={[1, 1, 1, 0.5]}
                          sx={{ minWidth: formMinWidth }}>
                          {' '}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                          <ArgonBox lineHeight={1.4}>
                            <ArgonTypography
                              display="block"
                              variant="caption"
                              fontWeight="bold">
                              Address line 1
                            </ArgonTypography>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="regular">
                              E.g. the event location name
                            </ArgonTypography>
                          </ArgonBox>
                        </TableCell>
                        <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                          <ArgonInput
                            id="address1"
                            name="address1"
                            placeholder="Address line 1"
                            size="small"
                            type="text"
                            value={formik.values.address1}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.address1 &&
                              Boolean(formik.errors.address1)
                            }
                            helperText={
                              formik.touched.address1 && formik.errors.address1
                            }
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                          <ArgonBox lineHeight={1.4}>
                            <ArgonTypography
                              display="block"
                              variant="caption"
                              fontWeight="bold">
                              Address line 2
                            </ArgonTypography>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="regular">
                              E.g. the street address
                            </ArgonTypography>
                          </ArgonBox>
                        </TableCell>
                        <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                          <ArgonInput
                            id="address2"
                            name="address2"
                            placeholder="Address line 2"
                            sx={{ minWidth: '250px' }}
                            size="small"
                            type="text"
                            value={formik.values.address2}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.address2 &&
                              Boolean(formik.errors.address2)
                            }
                            helperText={
                              formik.touched.address2 && formik.errors.address2
                            }
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                          <ArgonBox lineHeight={1.4}>
                            <ArgonTypography
                              display="block"
                              variant="caption"
                              fontWeight="bold">
                              Postal code
                            </ArgonTypography>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="regular">
                              Should be self explanatory
                            </ArgonTypography>
                          </ArgonBox>
                        </TableCell>
                        <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                          <ArgonInput
                            id="zip"
                            name="zip"
                            placeholder="Postal Code"
                            sx={{ minWidth: '250px' }}
                            size="small"
                            type="text"
                            value={formik.values.zip}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.zip && Boolean(formik.errors.zip)
                            }
                            helperText={formik.touched.zip && formik.errors.zip}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                          <ArgonBox lineHeight={1.4}>
                            <ArgonTypography
                              display="block"
                              variant="caption"
                              fontWeight="bold">
                              City
                            </ArgonTypography>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="regular">
                              Let your participants know where they need to go
                            </ArgonTypography>
                          </ArgonBox>
                        </TableCell>
                        <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                          <ArgonInput
                            id="city"
                            name="city"
                            placeholder="City"
                            sx={{ minWidth: '250px' }}
                            size="small"
                            type="text"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.city && Boolean(formik.errors.city)
                            }
                            helperText={
                              formik.touched.city && formik.errors.city
                            }
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                          <ArgonBox lineHeight={1.4}>
                            <ArgonTypography
                              display="block"
                              variant="caption"
                              fontWeight="bold">
                              Country
                            </ArgonTypography>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="regular">
                              Let your participants know where they need to go
                            </ArgonTypography>
                          </ArgonBox>
                        </TableCell>
                        <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                          <ArgonSelect
                            id="country"
                            name="country"
                            size="small"
                            // overflow
                            placeholder="Please choose"
                            options={selectData.countries.sort((a, b) =>
                              a.label.localeCompare(b.label)
                            )}
                            //value={formik.values.type}
                            value={selectData.countries.filter(
                              (option) =>
                                option.value === formik.values?.country
                            )}
                            onChange={(option) =>
                              handleSelectMenuChanged('country', option.value)
                            }
                            error={
                              formik.touched.country &&
                              Boolean(formik.errors.country)
                            }
                            // helperText={formik.touched.country && formik.errors.country}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                          <ArgonBox lineHeight={1.4}>
                            <ArgonTypography
                              display="block"
                              variant="caption"
                              fontWeight="bold">
                              Time zone
                            </ArgonTypography>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="regular">
                              Important to set dates right
                            </ArgonTypography>
                          </ArgonBox>
                        </TableCell>
                        <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                          <ArgonSelect
                            id="timeZone"
                            name="timeZone"
                            size="small"
                            // overflow
                            placeholder="Please choose"
                            options={timeZones}
                            //value={formik.values.type}
                            value={timeZones.filter(
                              (option) =>
                                option.value === formik.values?.timeZone
                            )}
                            onChange={(option) =>
                              handleSelectMenuChanged('timeZone', option.value)
                            }
                            error={
                              formik.touched.timeZone &&
                              Boolean(formik.errors.timeZone)
                            }
                            // helperText={formik.touched.country && formik.errors.country}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Collapse>
              </Grid>

              <Grid item xs={12}>
                <Table>
                  {/* Empty table head required to get rid of warning in dev tools */}

                  <TableHead></TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        Request Additional Information From Participants
                      </TableCell>
                      <TableCell
                        width="100%"
                        padding={[1, 1, 1, 0.5]}
                        sx={{ minWidth: formMinWidth }}>
                        {' '}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            variant="caption"
                            fontWeight="bold">
                            Country
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            Require each participant to provide the country he
                            wants to represent
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>
                      <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                        <Switch
                          id="askForCountry"
                          name="askForCountry"
                          onChange={formik.handleChange}
                          checked={formik.values?.askForCountry}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            variant="caption"
                            fontWeight="bold">
                            T-shirt size
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            Require each participant to provide his t-shirt size
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>
                      <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                        <Switch
                          id="askForTShirtSize"
                          name="askForTShirtSize"
                          onChange={formik.handleChange}
                          checked={formik.values?.askForTShirtSize}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>

              <Grid item xs={12}>
                <Table>
                  {/* Empty table head required to get rid of warning in dev tools */}
                  <TableHead></TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        Schedule Details
                      </TableCell>
                      {/* <TableCell
                        width="100%"
                        padding={[1, 1, 1, 0.5]}
                        sx={{ minWidth: formMinWidth }}>
                        {' '}
                      </TableCell> */}
                    </TableRow>
                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4} mb={1} ml={0.5}>
                          <ArgonTypography
                            display="block"
                            variant="caption"
                            fontWeight="bold">
                            Add information about the event schedule
                          </ArgonTypography>
                        </ArgonBox>
                        <ArgonEditor
                          id="scheduleDetails"
                          name="scheduleDetails"
                          uniqueKey={editor2Key}
                          value={formik.values.scheduleDetails}
                          onChange={(value) =>
                            formik.setFieldValue('scheduleDetails', value)
                          }
                          placeholder="You can add information about the event's schedule here"
                        />

                        <ArgonBox lineHeight={1.4} mt={2} mb={1} ml={0.5}>
                          <ArgonTypography
                            display="block"
                            variant="caption"
                            fontWeight="bold">
                            Attachments to Schedule Details
                          </ArgonTypography>
                          {scheduleAttachments?.length > 0 && (
                            <ArgonBox mt={2}>
                              {scheduleAttachments?.map((sa) => (
                                <ArgonBox
                                  key={sa._id}
                                  display="flex"
                                  alignItems="baseline"
                                  mb={0.5}>
                                  <ArgonTypography
                                    variant="caption"
                                    color="dark"
                                    fontWeight="regular">
                                    <li>
                                      <a
                                        href={sa.url}
                                        style={{ color: 'inherit' }}
                                        target="_blank">
                                        {sa.fileName}
                                      </a>
                                    </li>
                                  </ArgonTypography>
                                </ArgonBox>
                              ))}
                            </ArgonBox>
                          )}

                          <ArgonBox mt={2} />
                          {/* <ArgonButton
                              variant="outlined"
                              color="secondary"
                              size="small">
                              Add attachment
                            </ArgonButton>
                          </ArgonBox> */}
                          <ArgonDropzoneS3
                            id="upload_logo_schedule_attachments"
                            pathToPresignedUrl={
                              process.env
                                .REACT_APP_S3_MEDIA_UPLOAD_PRESIGNED_URL
                            }
                            //key={eventData?.pic_logo_url || 'emptyDropzone'}
                            onFileAdded={(url, fileName) => {
                              handleFileUploaded(url, fileName);
                            }}
                            payloadPurpose="eventinstance-schedule-attachment"
                            //image={eventData?.pic_logo_url}
                            metaData={{
                              event: eventInstance?.event?._id,
                              eventInstance: eventInstance?._id
                            }}
                            identifier={eventInstance?.event?._id}
                            options={{
                              // clickable: Boolean(eventData?._id),

                              paramName: 'file',

                              // dictDefaultMessage: "Put your custom message here",

                              method: 'PUT',
                              chunking: true,
                              chunkSize: 1000000,
                              parallelChunkUploads: true,
                              retryChunks: true,
                              retryChunksLimit: 3,
                              forceChunking: true,
                              // uploadMultiple: true,
                              parallelUploads: 3,

                              addRemoveLinks: true,
                              // maxFiles: 1,
                              acceptedFiles:
                                'image/*,.jpg,.jpeg,.png,.gif,.pdf,.txt',
                              autoQueue: true,
                              autoProcessQueue: true
                            }}
                          />
                        </ArgonBox>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>

              <Grid item xs={12} sm={12}>
                <ArgonBox display="flex" justifyContent="space-between">
                  <ArgonButton
                    variant="gradient"
                    color="info"
                    size="small"
                    onClick={formik.submitForm}
                    loading={workInProgress}
                    style={{ width: '200px' }}>
                    Save&nbsp;
                    {formik.values?.type
                      ? selectData.eventInstanceTypes.find(
                          (eit) => eit.value === formik.values.type
                        )?.label
                      : 'Event Instance Details'}
                  </ArgonButton>
                  <ArgonButton
                    variant="text"
                    color="error"
                    onClick={handleDeleteEventInstanceButtonClicked}>
                    <DeleteIcon />
                    &nbsp;Remove Event Instance
                    {/* <Icon>delete</Icon>&nbsp;Remove Event Instance */}
                  </ArgonButton>
                </ArgonBox>
              </Grid>
            </Grid>
          </ArgonBox>
        </Collapse>
      </Card>
      <ArgonBox mt={3}>
        <Card>
          <ArgonBox
            p={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => setDivisionsExpanded(!divisionsExpanded)}
            style={{ cursor: 'pointer' }}>
            <ArgonTypography variant="h5" textTransform="capitalize">
              Divisions
            </ArgonTypography>
            <ExpandMore
              expand={divisionsExpanded}
              onClick={() => setDivisionsExpanded(!divisionsExpanded)}
              aria-expanded={expanded}
              aria-label="show more">
              <ExpandMoreIcon />
            </ExpandMore>
          </ArgonBox>
          <Collapse in={divisionsExpanded}>
            <ArgonBox px={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <DivisionList
                    eventInstance={eventInstance}
                    event={event}
                    onDivisionsChanged={handleDivisionsChanged}
                  />
                </Grid>
              </Grid>
            </ArgonBox>
          </Collapse>
        </Card>
      </ArgonBox>

      {/* ########################### AUTO INVITATION ########################### */}
      <ArgonBox mt={3}>
        <Card>
          <ArgonBox
            p={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => setAutoInvitationsExpanded(!autoInvitationsExpanded)}
            style={{ cursor: 'pointer' }}>
            <ArgonTypography variant="h5" textTransform="capitalize">
              Automatic Invitations
            </ArgonTypography>

            <ExpandMore
              expand={autoInvitationsExpanded}
              onClick={() => () =>
                setAutoInvitationsExpanded(!autoInvitationsExpanded)}
              aria-expanded={expanded}
              aria-label="show more">
              <ExpandMoreIcon />
            </ExpandMore>
          </ArgonBox>

          <Collapse in={autoInvitationsExpanded}>
            <ArgonBox p={3} display="flex" flexDirection="column" gap={2}>
              {/* <ArgonTypography variant="caption" color="text">
                Open the Settings dialog to configure automatic invitations.
              </ArgonTypography> */}
              <ArgonBox>
                <ArgonButton
                  id="open_auto_invitation_wizard"
                  name="open_auto_invitation_wizard"
                  size="small"
                  variant="outlined"
                  color="dark"
                  onClick={() =>
                    setAutoInvitationWizardOpen(!autoInvitationWizardOpen)
                  }>
                  Setup Auto Invitations
                </ArgonButton>
              </ArgonBox>

              {(data.eventInstance?.autoInvitationData ||
                showInvitationProgress) && (
                <ArgonBox mt={2}>
                  <Table>
                    {/* Empty table head required to get rid of warning in dev tools */}
                    <TableHead></TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                          InvitationProgress
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <ArgonBox mt={2} />

                  <AutoInvitationStatistics
                    eventInstanceId={data.eventInstance?._id}
                    invitationsPerDivision={
                      data.eventInstance?.autoInvitationData
                        ?.invitationsPerDivision
                    }
                  />
                </ArgonBox>
              )}
            </ArgonBox>
          </Collapse>
        </Card>
      </ArgonBox>
      <AutoInvitationSettingsWizard
        eventInstanceId={eventInstanceId}
        open={autoInvitationWizardOpen}
        onClose={async () => {
          setAutoInvitationWizardOpen(!autoInvitationWizardOpen);
          await refetch();
        }}
        onSaved={async () => {
          setAutoInvitationWizardOpen(!autoInvitationWizardOpen);
          setShowInvitationProgress(true);
          await refetch();
        }}
      />
    </>
  );
}

export default EventInstanceDetails;
