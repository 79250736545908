import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

import Pool from './userPool';

import { groupsWithOrganizerAccess } from './groupsWithOrganizerAccess';

const getSession = async () =>
  await new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession(async (err, session) => {
        if (err) {
          console.log('Error getting user session: ', err);
          reject(err);
        } else {
          // setUserData(session);

          const attributes = await new Promise((resolve, reject) => {
            user.getUserAttributes((err, attributes) => {
              if (err) {
                console.log('Error getting user attributes: ', err);
                // forwardToLogin();
                reject(err); // not useful here after redirecting... I know... ;)
              } else {
                const results = {};

                for (let attribute of attributes) {
                  const { Name, Value } = attribute;
                  results[Name] = Value;
                }

                resolve(results);
              }
            });
          });

          resolve({
            user,
            ...session,
            ...attributes
          });
        }
      });
    } else {
      console.log('User is not logged in.');
      reject('User is not logged in.');
    }
  });

// const forwardToLogin = () => {
//   window.sessionStorage.removeItem('hash');
//   window.location.href = '/login';
// };

const authenticate = async (Username, Password) =>
  await new Promise((resolve, reject) => {
    const user = new CognitoUser({ Username, Pool });
    const authDetails = new AuthenticationDetails({ Username, Password });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        data.authChallenge = 'none';
        console.log('Successfully authenticated in cognito.', data);
        resolve(data);
      },

      onFailure: (err) => {
        console.error('onFailure:', err);
        reject(err);
      },

      newPasswordRequired: (data) => {
        //console.log('newPasswordRequired:', data);
        data.authChallenge = 'newPasswordRequired';
        resolve(data);
      }
    });
  });

const verifyEmail = async (Username, Code) =>
  await new Promise((resolve, reject) => {
    const user = new CognitoUser({ Username, Pool });

    user.confirmRegistration(Code, true, function (err, result) {
      if (err) {
        reject(err);
      }
      console.log('verification result: ' + result);
      resolve(result);
    });
  });

const resendVerificationCode = async (Username) =>
  await new Promise((resolve, reject) => {
    const user = new CognitoUser({ Username, Pool });

    user.resendConfirmationCode((err, result) => {
      if (err) {
        console.error('resendConfirmationCode error:', err);
        reject(err);
      } else {
        console.log('resendConfirmationCode success:', result);
        resolve(result);
      }
    });
  });

const completeNewPasswordChallenge = (Username, Password, newPassword) =>
  new Promise((resolve, reject) => {
    console.log('Username', Username);
    console.log('Old Password', Password);
    console.log('New Password', newPassword);
    const user = new CognitoUser({ Username, Pool });
    const authDetails = new AuthenticationDetails({ Username, Password });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        data.authChallenge = 'none';
        resolve(data);
      },

      onFailure: (err) => {
        console.error('onFailure:', err);
        reject(err);
      },

      newPasswordRequired: (data) => {
        console.log('newPasswordRequired:', data);

        user.completeNewPasswordChallenge(newPassword, [], {
          onSuccess: (session) => {
            // login
            console.log('Password changed succesfully.', session);
            resolve(session);
          },
          onFailure: (err) => {
            console.error('Failed changing password:', err);
            reject(err);
          }
        });

        // resolve(data);
      }
    });
  });

const logout = () => {
  const user = Pool.getCurrentUser();

  if (user) {
    user.signOut();
  }
};

const getUserId = async () =>
  new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession(async (err, session) => {
        if (err) {
          console.error(err);
          reject(err);
        } else {
          resolve(session.idToken.payload['custom:userId']);
        }
      });
    } else {
      reject('User is not logged in.');
    }
  });

const getIdToken = async () =>
  new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession(async (err, session) => {
        if (err) {
          console.error(err);
          reject(err);
        } else {
          resolve(session.idToken.jwtToken);
        }
      });
    } else {
      reject('User is not logged in.');
    }
  });

const getUserGroups = async () =>
  new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession(async (err, session) => {
        if (err) {
          console.error(err);
          reject(err);
        } else {
          resolve(session.idToken.payload['cognito:groups']);
        }
      });
    } else {
      resolve([]);
    }
  });

const checkIfUserHasOrganizerAccess = async () =>
  new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession(async (err, session) => {
        if (err) {
          console.error(err);
          // reject(err);
          console.log('Checking for organizer access: user is not logged in.');
          resolve(false);
        } else {
          const userGroups = session.idToken.payload['cognito:groups'];

          // check if userGroups contains any of the group prefixes with organizer access
          const hasOrganizerAccess = Boolean(
            userGroups?.some((userGroup) =>
              groupsWithOrganizerAccess?.some((prefix) =>
                userGroup?.startsWith(prefix)
              )
            )
          );
          console.log('User has organizer access:', hasOrganizerAccess);
          resolve(hasOrganizerAccess);
        }
      });
    } else {
      resolve(false);
    }
  });

const checkIfUserIsLoggedIn = async () =>
  new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      resolve(true);
    } else {
      resolve(false);
    }
  });

const getEventIdsWithAdminAccess = async () =>
  new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession(async (err, session) => {
        if (err) {
          console.error(err);
          // reject(err);
          console.log('Checking for organizer access: user is not logged in.');
          resolve(false);
        } else {
          const userGroups = session.idToken.payload['cognito:groups'];

          // // check if userGroups contains any of the group prefixes with organizer access
          // const hasOrganizerAccess = Boolean(
          //   userGroups?.some((userGroup) =>
          //     groupsWithOrganizerAccess?.some((prefix) =>
          //       userGroup?.startsWith(prefix)
          //     )
          //   )
          // );
          // console.log('User has organizer access:', hasOrganizerAccess);

          const eventIds = userGroups
            ?.filter((userGroup) => userGroup?.startsWith('event-admin-'))
            .map((eventGroup) => eventGroup.split('-').pop());

          console.log('EventIds with admin access:', eventIds);
          resolve(eventIds);
        }
      });
    } else {
      resolve(false);
    }
  });

const getEventIdsWithScoringAccess = async () =>
  new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession(async (err, session) => {
        if (err) {
          console.error(err);
          // reject(err);
          console.log('Checking for organizer access: user is not logged in.');
          resolve(false);
        } else {
          const userGroups = session.idToken.payload['cognito:groups'];

          // // check if userGroups contains any of the group prefixes with organizer access
          // const hasOrganizerAccess = Boolean(
          //   userGroups?.some((userGroup) =>
          //     groupsWithOrganizerAccess?.some((prefix) =>
          //       userGroup?.startsWith(prefix)
          //     )
          //   )
          // );
          // console.log('User has organizer access:', hasOrganizerAccess);

          const eventIds = userGroups
            ?.filter(
              (userGroup) =>
                userGroup?.startsWith('event-admin-') ||
                userGroup?.startsWith('event-scoring-')
            )
            .map((eventGroup) => eventGroup.split('-').pop());

          console.log('EventIds with scoring access to user:', eventIds);
          resolve(eventIds);
        }
      });
    } else {
      resolve(false);
    }
  });

const refreshIdToken = async () =>
  new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      // 1) get current session => refreshToken
      user.getSession(async (err, session) => {
        if (err) {
          console.error(err);
          reject(err);
        } else {
          // 2) use refreshtoken to refresh the session

          user.refreshSession(session.refreshToken, async (err, session) => {
            if (err) {
              console.error(err);
              reject(err);
            } else {
              console.log('Refreshed session: ', session);
              resolve(session);
            }
          });
        }
      });
    }
  });

export {
  getSession,
  authenticate,
  verifyEmail,
  resendVerificationCode,
  logout,
  getIdToken,
  getUserId,
  getUserGroups,
  checkIfUserIsLoggedIn,
  checkIfUserHasOrganizerAccess,
  refreshIdToken,
  completeNewPasswordChallenge,
  getEventIdsWithAdminAccess,
  getEventIdsWithScoringAccess
};
